import FormGroupInput from "./Inputs/FormGroupInput.vue";
// import DragAndDropMultipleFileUploader from "./Inputs/DragAndDropMultipleFileUploader.vue";

import DropDown from "./Dropdown.vue";
import PaperTable from "./PaperTable.vue";
import Button from "./Button";

import Card from "./Cards/Card.vue";
import CardColapsive from "./Cards/CardColapsive.vue";
import ChartCard from "./Cards/ChartCard.vue";
import StatsCard from "./Cards/StatsCard.vue";

import SidebarPlugin from "./SidebarPlugin/index";

let components = {
  FormGroupInput,
  // DragAndDropMultipleFileUploader,
  Card,
  CardColapsive,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  SidebarPlugin,
};

export default components;

export {
  FormGroupInput,
  // DragAndDropMultipleFileUploader,
  Card,
  CardColapsive,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  Button,
  SidebarPlugin,
};
