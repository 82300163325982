<template>
  <div class="form-group" :class="{ 'input-group': hasIcon }">
    <slot name="label">
      <label v-if="label && id" :for="id" class="control-label btn btn-sm btn-outline-primary label-button">
        {{ label }}
      </label>
      <label v-else-if="label" class="control-label">
        {{ label }}
      </label>
    </slot>

    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <i :class="addonLeftIcon" class="input-group-text"></i>
      </span>
    </slot>

    <input
      :id="id"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @change="emitChange"
      v-bind="$attrs"
      :class="computedClasses"
      aria-describedby="addon-right addon-left"
    />

    <slot></slot>
    
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <i :class="addonRightIcon" class="input-group-text"></i>
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "fg-input",
  props: {
    id: {
      type: String,
      default: null,
    },
    label: String,
    value: [String, Number],
    addonRightIcon: String,
    addonLeftIcon: String,
    additionalClasses: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitChange(event) {
      this.$emit('change', event)
    }
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },computedClasses() {
      const baseClass = "form-control";
      // Adiciona as classes recebidas por propriedade, se houver
      return `${baseClass} ${this.additionalClasses}`.trim();
    },
  },
};
</script>

<style scoped>
.label-button {
  text-transform: none;
}
</style>
