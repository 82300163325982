<template>
  <table class="table" :class="tableClass">
    <thead>
      <slot name="columns">
        <th v-for="(column, index) in columns" :key="index" v-if="column.visible">
          {{ column.title }}
        </th>
      </slot>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index" @click="onRowClickEvent(index)" class="table-record">
        <slot :row="item">
          <td v-for="(column, index) in columns" :key="index" v-if="hasValue(item, column)">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
      <tr v-if="data.length == 0"class="table-record">
        <td :colspan="columns.length-1">
          <span>No data</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "paper-table",
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.field] !== "undefined" && column.visible;
    },
    itemValue(item, column) {
      return column.value(item[column.field]);
    },
    onRowClickEvent(index) {
      this.$emit('row-clicked', index)
    }
  },
};
</script>
<style scoped>
.table-record {
  cursor: pointer;
}
</style>
