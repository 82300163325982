<template>
  <div v-if="visible" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
        <div class="modal-header">
          <div class="row w-100">
            <div class="col-11">
              <slot name="header"></slot>
            </div>
            <div class="col-1 close-div">
              <button class="close-button" @click.prevent="closeModal">x</button>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>
        
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
}
.modal-header {
  display: flex;
  align-items: center;
  padding-right: 0;
}
.close-div {
  display: flex;
  justify-content: right;
  padding: 0;
}
.close-button {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.modal-content {
  background: white;
  padding: 0 20px 0 20px;
  margin: 5%;
  border-radius: 8px;
  position: relative;
  opacity: 1;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
</style>