<template>
  <div class="wrapper">

    <side-bar>

      <template slot="links">
        <sidebar-link
          to="/document/analysis"
          name="Documentos"
          icon="ti-check"
        />
      </template>
      
      <mobile-menu>
        <!-- <li class="nav-item">
          <a class="nav-link">
            <i class="ti-settings"></i>
            <p>Settings</p>
          </a>
        </li> -->
        <sidebar-link
            v-if="!logged" 
            to="/login"
            name="Login"
            icon="ti-panel"
          />
          <li v-else class="nav-item">
            <a href="javascript:void(0)" class="nav-link" @click="handleLogout">
              <i class="ti-panel"></i>
              <p>Logout</p>
            </a>
          </li>
        <li class="divider"></li>
      </mobile-menu>

    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>

  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  data() {
    return {
      logged: null
    };
  },
  beforeMount() {
    this.logged = localStorage.getItem("authToken")
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    handleLogout() {
      localStorage.removeItem("authToken")
      localStorage.removeItem("refreshToken");
      this.logged = null

      // this.$store. dispatch('setAuth', false);
      // this.$store.dispatch('setUser', null);

      this.$router.push("/login")
    }
  },
};
</script>

<style lang="scss"></style>
