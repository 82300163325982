import BaseService from './BaseService';

class AuthService extends BaseService {
  constructor() {
    super()
  }

  login(data) {
    return this.http.post('/login', data, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  logout() {
    localStorage.removeItem("authToken")
    this.$store.dispatch('setAuth', true);
    this.$store.dispatch('setUser', null);
    this.$router.push("/login")
  }
}

export { AuthService };
