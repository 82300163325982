<template>
  <div class="card">
    <div class="card-header pb-3" v-if="$slots.header || title">
      <slot name="header">
        <div class="row m-0 p-0">
          <div class="col-11 p-0 m-0">
            <h5 class="card-title">{{ title }}</h5>
            <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
          </div>
          <div class="col-1 p-0 m-0">
            <button class="btn-primary" v-if="collapsible" @click="toggleCollapse">
              <span :class="isCollapsed ? 'ti-angle-down' : 'ti-angle-up'"></span>
            </button>
          </div>
        </div>
      </slot>
    </div>
    <div v-show="!isCollapsed">
      <div class="card-image" v-if="$slots.image">
        <slot name="image"></slot>
      </div>
      <div class="card-body" v-if="$slots.default">
        <slot></slot>
      </div>
      <slot name="raw-content"></slot>
      <div class="card-footer" v-if="$slots.footer">
        <hr />
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    title: String,
    subTitle: String,
    collapsible: Boolean, // Habilitar funcionalidade de colapsar
  },
  data() {
    return {
      isCollapsed: true, // Estado inicial do colapsável
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed; // Alterna entre colapsado e expandido
    },
  },
};
</script>

<style>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
}
button:hover {
  text-decoration: underline;
}
</style>