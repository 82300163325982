<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ routeName }}</a>
      
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          
          <sidebar-link
            v-if="!logged" 
            to="/login"
            name="Login"
            icon="ti-panel"
          />
          <li v-else class="nav-item">
            <a href="javascript:void(0)" class="nav-link" @click="handleLogout">
              <i class="ti-panel"></i>
              <p>Logout</p>
            </a>
          </li>

          <!-- <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <i class="ti-settings"></i>
              <p>Settings</p>
            </a>
          </li> -->

        </ul>
      </div>

    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      logged: null
    };
  },
  beforeMount() {
    this.logged = localStorage.getItem("authToken")
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    handleLogout() {
      localStorage.removeItem("authToken")
      localStorage.removeItem("refreshToken");
      this.logged = null

      // this.$store. dispatch('setAuth', false);
      // this.$store.dispatch('setUser', null);

      this.$router.push("/login")
    }
  },
};
</script>
<style></style>
