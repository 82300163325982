import axios from 'axios';

class BaseService {
  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 180000, // 3 min
      headers: {
        common: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }
    });

    // Interceptores de requisição e resposta
    this.http.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem("authToken");

        if (token)
          config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      error => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      response => response,
      async error => {
        const refreshToken = localStorage.getItem("refreshToken");

        if (error.response && error.response.status === 401 && refreshToken) {
          const response = await this.http.post("/refresh", { refreshToken });
    
          localStorage.setItem("authToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
    
          // Repetindo a requisição original com o novo token
          error.config.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
          return this.http.request(error.config);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  gerarQueryString(obj) {
    const params = new URLSearchParams(obj);
    return params.toString();
  }
}

export default BaseService;
