<template>
  <div class="form-group">
    <label for="combobox" class="control-label">{{ title ?? 'Escolha uma opção:' }}</label>
    <select id="combobox" name="combobox" class="form-control pl-0" @change="emitSelection($event)">
      <option v-for="(option, index) in options" :key="index" :value="option.id">
        {{ option.name }}
      </option>
      <option v-if="options.length == 0">
        No options
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Combobox",
  props: {
    options: {
      type: Array,
      required: true
    },
    title: String
  },
  methods: {
    emitSelection(event) {
      this.$emit("selected", event.target.value);
    }
  }
};
</script>