<template>
  <div>
    <div class="d-flex justify-content-center align-items-center vh-100 login-page-back">
      <div class="card p-4" style="width: 300px;">
        <h3 class="text-center">Login</h3>
        <form @submit.prevent="submitForm" novalidate>
          <div class="form-group">
            <label for="username">Usuário</label>
            <input
              type="text"
              id="username"
              v-model="formData.username"
              class="form-control"
              :class="{ 'is-invalid': errors.username }"
              placeholder="Digite seu usuário"
              required
            />
            <div class="invalid-feedback" v-if="errors.username">{{ errors.username }}</div>
          </div>
          <div class="form-group">
            <label for="password">Senha</label>
            <input
              type="password"
              id="password"
              v-model="formData.password"
              class="form-control"
              :class="{ 'is-invalid': errors.password }"
              placeholder="Digite sua senha"
              required
            />
            <div class="invalid-feedback" v-if="errors.password">{{ errors.password }}</div>
          </div>
          <button type="submit" class="btn btn-primary btn-block">Entrar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/services/AuthService';

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      formData: {
        username: '',
        password: ''
      },
      errors: {}
    };
  },
  methods: {
    validateForm() {
      const newErrors = {};
      if (!this.formData.username.trim()) {
        newErrors.username = 'O campo Usuário é obrigatório.';
      }
      if (!this.formData.password.trim()) {
        newErrors.password = 'O campo Senha é obrigatório.';
      } else if (this.formData.password.length < 6) {
        newErrors.password = 'A senha deve ter pelo menos 6 caracteres.';
      }
      this.errors = newErrors;
      return Object.keys(newErrors).length === 0;
    },

    async submitForm() {
      if (!this.validateForm()) {
        return;
      }
      
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        color: '#164464',
        loader: 'dots'
      });

      try {
        const authService = new AuthService();
        
        const formData = new FormData();
        formData.append("email", this.formData.username);
        formData.append("password", this.formData.password);
        formData.append("twoFactorCode", '');
        formData.append("twoFactorRecoveryCode", '');

        const response = await authService.login(formData);

        if (response.status == 200) {
          let responseData = response.data;

          localStorage.setItem("authToken", responseData.accessToken);
          localStorage.setItem("refreshToken", responseData.refreshToken);
          
          this.$router.push("/");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$notify(
            {
              message: 'Credenciais inválidas',
              icon: 'fa fa-gift',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger'
            })
        } else {
          this.$notify(
            {
              message: 'Erro ao tentar fazer login: '+ error,
              icon: 'fa fa-gift',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger'
            })
        }
        console.error('Erro ao tentar fazer login:', error);
      } finally {
        loader.hide()
      }
    },

    onCancel() {
      return
    }
  }
};
</script>

<style scoped>
.vh-100 {
  height: 100vh;
}
.is-invalid {
  border-color: #dc3545;
}
.invalid-feedback {
  display: block;
  color: #dc3545;
}
.login-page-back {
  background-color: #F4F3EF;
}
</style>
