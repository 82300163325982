import BaseService from './BaseService';

class RequestLogService extends BaseService {
  constructor() {
    super();
  }

  getLogs(objFilters) {
    let filters = this.gerarQueryString(objFilters)

    return this.http.get(`/request-logs?${filters}`);
  }
}

export { RequestLogService };
