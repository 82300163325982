import BaseService from './BaseService';

class OpenAIService extends BaseService {
  constructor() {
    super(); // Chama o construtor da classe pai
  }

  postData(data) {
    return this.http.post('/analyze-documents', data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  
  getTest() {
    return this.http.get(`/test-connection`);
  }
  
  postTranslateRequest(data) {
    return this.http.post(`/translate`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
}

export { OpenAIService };
