import BaseService from './BaseService';

class UserService extends BaseService {
  constructor() {
    super();
  }

  getUserOptions(objFilters) {
    let filters = this.gerarQueryString(objFilters)

    return this.http.get(`/users?${filters}`);
  }
}

export { UserService };
