<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul>
          <li>
            <router-link :to="{ path: '/' }">Home</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright d-flex flex-wrap">
        &copy; <a href="https://ayamofoods.com/" target="_blank">&nbsp; Ayamo Global Foods</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
