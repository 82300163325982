<template>
<div>
  <!-- Filtros -->
  <div class="row">
    <div class="col-12">
      <card-colapsive :title="filterTitle" :collapsible="true">
        <div class="row">

          <div class="col-md-2">
            <fg-input
              type="text"
              label="Processo"
              placeholder="Número do processo"
              v-model="filters.Process"
            >
            </fg-input>
          </div>
          
          <div class="col-2">
            <Combobox :options="userOptions" title="Usuário" @selected="handleUserSelection" />
          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="startDate" class="control-label">Data inicial</label>
              <date-picker v-model="filters.StartDate" valueType="date" id="startDate" name="startDate" class="form-control pl-0"></date-picker>
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="endDate" class="control-label">Data final</label>
              <date-picker v-model="filters.EndDate" valueType="date" id="endDate" name="endDate" class="form-control pl-0"></date-picker>
            </div>
          </div>

          <div class="col-2">
            <Combobox :options="statusOptions" title="Status" @selected="handleStatusSelection" />
          </div>

          <div class="col-sm-2">
            <p-button type="primary" size="sm" class="mx-1 mt-4" @click.native.prevent="handleFilter">
              Filtrar
            </p-button>
            <p-button type="primary" size="sm" class="mx-1 mt-4" @click.native.prevent="cleanFilter">
              Limpar
            </p-button>
          </div>

        </div>
      </card-colapsive>
    </div>
  </div>

  <!-- Dados -->
  <div class="row">

    <div class="col-12">
      <card :title="table.title" :subTitle="table.subTitle">
        <div slot="raw-content" class="table-responsive">

          <!-- Tabela -->
          <paper-table
            type="hover" 
            :data="tableData" 
            :columns="table.columns"
            @row-clicked="handleRowClicked"
          ></paper-table>

          <!-- Modal -->
          <Modal :visible="showModal" @close="showModal = false">
            <template #header>
              <h5 class="m-0">Processo {{ modalData ? modalData.process :'' }}</h5>
            </template>

            <div class="row">
              <div class="col-5 px-4">
                <div class="row mb-2">
                  <p>Processo: {{ modalData ? modalData.process : '' }}</p>
                </div>

                <div class="row mb-2">
                  <div class="col-12 p-0">
                    <p>Arquivos de Modelo</p>
                  </div>
                  <div class="col-12 p-0">
                    <ul class="file-list">
                      <li v-for="(modelo, index) in modalData.modelFiles" :key="index">{{modelo}}</li>
                    </ul>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-12 p-0">
                    <p>Arquivos Analizados</p>
                  </div>
                  <div class="col-12 p-0">
                    <ul class="file-list">
                      <li v-for="(analized, index) in modalData.analysedFiles" :key="index">{{analized}}</li>
                    </ul>
                  </div>
                </div>

                <div class="row mb-2">
                  <p>Usuário: {{ modalData ? modalData.firstName : '' }}</p>
                </div>

                <div class="row mb-2">
                  <p>Data: {{ modalData ? modalData.createdAt : '' }}</p>
                </div>

                <div class="row mb-2">
                  <p>Status da análise: {{ modalData ? modalData.status : '' }}</p>
                </div>

                <div class="row mb-2" v-if="modalData && modalData.error">
                  <p>Erro: {{ modalData.error }}</p>
                </div>
              </div>

              <div class="col-7">
                <div class="col-12 p-0">
                  <p>Resultado:</p>
                </div>
                <div class="col-12">
                  <!-- <p>{{ modalData ? modalData.result : '' }}</p> -->
                  <textarea
                    rows="20"
                    class="form-control border-input bg-light text-dark normal-cursor"
                    placeholder=''
                    v-model="modalData.result"
                    readonly
                  >
                  </textarea>
                </div>
              </div>
            </div>
            
            <template #footer>
              <!-- <div class="row w-100"> -->
                <div class="col-12 d-flex justify-content-center m-3">
                  <button type="button" class="btn btn-secondary ml-2" @click="showModal = false">Fechar</button>
                </div>
              <!-- </div> -->
            </template>
          </Modal>

        </div>
      </card>
    </div>

  </div>
</div>
</template>
<script>
import { PaperTable } from "@/components";
import Modal from '@/components/Modal/SimpleModal.vue';
import Combobox from "@/components/Inputs/Combobox.vue";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'vue2-datepicker/locale/pt-br';

import { RequestLogService } from '@/services/RequestLogService';
import { UserService } from '@/services/UserService';

const tableColumns = [
  {
    field: "id",
    title: "Id",
    value: (data) => data, 
    visible: false
  }, 
  {
    field: "process",
    title: "Processo",
    value: (data) => data, 
    visible: true
  }, 
  {
    field: "modelFiles",
    title: "Modelos",
    value: (data) => data ? data.split(';').length : 0, 
    visible: true
  }, 
  {
    field: "analysedFiles",
    title: "Analisados",
    value: (data) => data ? data.split(';').length : 0, 
    visible: true
  },
  {
    field: "createdAt",
    title: "Data",
    value: (data) => {
      let dateObject = new Date(data);

      return dateObject.toLocaleDateString('pt-BR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }) + ' ' + dateObject.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
      })
    }, 
    visible: true
  }, 
  {
    field: "firstName",
    title: "Usuário",
    value: (data) => data, 
    visible: true
  },
  {
    field: "status",
    title: "Análise",
    value: (data) => statusNames[data], 
    visible: true
  } 
];

// const tableData = [
//   {
//     id: 1,
//     process: "S8600",
//     modelFiles: "Carta-de-Credito-Modelo-Correto.pdf",
//     analysedFiles: "BIG0052-24-03-INVOICE.pdf;BIG0052-24-03-PACKING_LIST.pdf",
//     status: 1,
//     firstName: "Marcelo",
//     createdAt: "2025-03-27T19:58:10.137Z"
//   },
//   {
//     id: 2,
//     process: "S8700",
//     modelFiles: "Carta-de-Credito-Modelo-Correto.pdf",
//     analysedFiles: "BIG0052-24-03-INVOICE.pdf",
//     status: 2,
//     firstName: "Admin",
//     createdAt: "2025-03-27T19:58:10.137Z"
//   },
//   // {
//   //   id: 2,
//   //   process: "S8700",
//   //   modelFiles: "Carta-de-Credito-Modelo-Correto.pdf",
//   //   analysedFiles: "BIG0052-24-03-INVOICE.pdf",
//   //   step: " ",
//   //   result: "",
//   //   status: 2,
//   //   error: "",
//   //   userId: "",
//   //   firstName: "Admin",
//   //   createdAt: "2025-03-27T19:58:10.137Z"
//   // }
// ];

const statusNames = [
  'N/A', 
  'Finalizada', 
  'Erro'
]

export default {
  components: {
    PaperTable,
    Modal, 
    Combobox,
    DatePicker,
  },
  data() {
    return {
      filterTitle: 'Filtros',
      filters: {
        Process: null,
        UserId: null,
        Status: null,
        StartDate: null,
        EndDate: null,
      },
      userOptions: [],
      statusOptions: [
        {
          id: null,
          name: 'Todas'
        }, 
        {
          id: 1,
          name: 'Finalizada', 
        },
        {
          id: 2,
          name: 'Erro'
        }
      ],
      table: {
        title: "",
        subTitle: "",
        columns: [...tableColumns],
      },
      tableData: [
        // {
        //   id: 1,
        //   process: "S8600",
        //   modelFiles: "Carta-de-Credito-Modelo-Correto.pdf",
        //   analysedFiles: "BIG0052-24-03-INVOICE.pdf;BIG0052-24-03-PACKING_LIST.pdf",
        //   status: 1,
        //   firstName: "Marcelo",
        //   createdAt: "2025-03-27T19:58:10.137Z"
        // },
        // {
        //   id: 2,
        //   process: "S8700",
        //   modelFiles: "Carta-de-Credito-Modelo-Correto.pdf",
        //   analysedFiles: "BIG0052-24-03-INVOICE.pdf",
        //   status: 2,
        //   firstName: "Admin",
        //   createdAt: "2025-03-27T19:58:10.137Z"
        // }
      ],
      showModal: false, 
      modalData: {
        id: null,
        process: null,
        modelFiles: [],
        analysedFiles: [],
        status: null,
        firstName: null,
        createdAt: null,
        result: null,
        error: null,
      },
    };
  },
  beforeMount() {
    this.getUserOptions()
    this.getLogs()
  },
  methods: {
    async getUserOptions() {
      const userService = new UserService();

      try {
        let filters = {
          UserId: null,
          UserName: null
        }

        const response = await userService.getUserOptions(filters);
        let all = [{
          id: null,
          name: 'Todos'
        }]
        let users = response.data.map(item => {
          return {
            id: item.id,
            name: item.firstName
          }
        })
        this.userOptions = [...all, ...users];
        
      } catch (error) {
        this.$notify(
          {
            message: 'Erro ao consultar opcoes de usuários: '+ error,
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })
        console.error('Erro ao consultar opcoes de usuários:', error);
      }
    },
    
    formatDate(dateString) {
      const dateObject = new Date(dateString);

      return dateObject.toLocaleDateString('pt-BR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }) + ' ' + dateObject.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
      });
    },

    handleRowClicked(index) {
      let record = this.tableData[index]

      this.modalData = {
        id: record.id,
        process: record.process,
        modelFiles: record.modelFiles ? record.modelFiles.split(';') : [],
        analysedFiles: record.analysedFiles ? record.analysedFiles.split(';') : [],
        status: statusNames[record.status],
        firstName: record.firstName,
        createdAt: this.formatDate(record.createdAt),
        result: record.result.replace('#', '').replace('**', ''),
        error: record.error,
      }

      this.showModal = true
    }, 

    handleUserSelection(value) {
      this.filters.UserId = value
    },

    handleStatusSelection(value) {
      this.filters.Status = value
    },

    async handleFilter() {
      await this.getLogs()
    }, 

    cleanFilter() {
      this.filters = {
        Process: null,
        UserId: null,
        Status: null,
        StartDate: null,
        EndDate: null,
      }
      
      this.handleFilter()
    },

    async getLogs() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        color: '#164464',
        loader: 'dots'
      });

      let filters = {}

      if (this.filters.Process)
        filters.Process = this.filters.Process

      if (this.filters.UserId)
        filters.UserId = this.filters.UserId

      if (this.filters.StartDate)
        filters.StartDate = new Date(this.filters.StartDate).toISOString()

      if (this.filters.EndDate)
        filters.EndDate = new Date(this.filters.EndDate).toISOString()

      if (this.filters.Status)
        filters.Status = this.filters.Status

      const requestLogService = new RequestLogService();

      try {
        const response = await requestLogService.getLogs(filters);
        this.tableData = response.data;
        
      } catch (error) {
        this.$notify(
          {
            message: 'Erro ao carregar logs: '+ error,
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })
        console.error('Erro ao carregar logs:', error);
      } finally {
        loader.hide()
      }
    },
  }
}
</script>

<style scoped>
.file-list {
  list-style-type: square;
}
.normal-cursor {
  cursor: default !important;
}
</style>
