import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: null
  },
  mutations: {
      SET_AUTH: (state) => state.isAuthenticated = auth,
      SET_USER: (user) => state.user = user
  },
  actions: {
      setAuth: ({commit}) => commit('SET_AUTH', auth),
      setUser: ({commit}) => commit('SET_USER', user)
  },
  modules: {}
})
