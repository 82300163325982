<template>

  <div>
    <div class="row">
      <!-- Form -->
      <div class="col-md-4">
        <card class="card" title="Parâmetros">
          <div>
            <form @submit.prevent>
              <!-- Número do processo -->
              <div class="row">
                <div class="col-md-5">
                  <fg-input
                    type="text"
                    label="Processo"
                    placeholder="Número do processo"
                    v-model="formData.process"
                    class="mb-1"
                    required
                  >
                  </fg-input>
                </div>
              </div>

              <!-- Arquivos de modelo -->
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <fg-input
                      type="file"
                      label="Arquivos de modelo"
                      accept=".pdf"
                      @change="handleFileChange('model', $event)"
                      multiple
                      id="modelFiles"
                      additionalClasses="d-none"
                      class="mb-0"
                    >
                    </fg-input>
                    <ul class="horizontal-list">
                      <li v-for="(document, index) in formData.documentsModel" :key="index" class="pr-1">
                        <small>{{ document.name }} <a href="#" @click.prevent="removeItem('model', index)"><i class="ti-close"></i></a></small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Arquivos a verificar -->
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <fg-input
                      type="file"
                      label="Arquivos a verificar"
                      @change="handleFileChange('verify', $event)"
                      multiple
                      id="analysisFiles"
                      additionalClasses="d-none"
                      class="mb-0"
                    >
                    </fg-input>
                    <ul class="horizontal-list">
                      <li v-for="(document, index) in formData.documentsToCheck" :key="index" class="pr-1">
                        <small>{{ document.name }} <a href="#" @click.prevent="removeItem('verify', index)"><i class="ti-close"></i></a></small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Buttons -->
              <div class="text-center pt-3">
                <p-button type="primary" size="sm" @click.native.prevent="sendData">
                  Analisar
                </p-button>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </card>
      </div>

      <!-- Info -->
      <div class="col-md-8">
        <card class="card" title="Resultado">
          <div>
            <div class="row">
              <div class="col-12 px-4" id="result-card">
                <!-- <textarea
                    rows="20"
                    class="form-control border-input bg-light text-dark normal-cursor"
                    placeholder=''
                    v-model="screenResultText"
                    readonly
                  >
                  </textarea> -->
              </div>
            </div>

            <!-- Buttons -->
            <div class="text-center pt-3">
              <p-button type="primary" size="sm" @click.native.prevent="newAnalysis" class="mx-1">
                Nova Análise
              </p-button>

              <p-button type="primary" size="sm" class="mx-1" @click.native.prevent="setPortuguese" :disabled="!analysed">
                <span class="fi fi-br"></span>
              </p-button>

              <p-button type="primary" size="sm" class="mx-1" @click.native.prevent="setEnglish" :disabled="!analysed">
                <span class="fi fi-us"></span>
              </p-button>

              <p-button type="primary" size="sm" class="mx-1" @click.native.prevent="setSpanish" :disabled="!analysed">
                <span class="fi fi-es"></span>
              </p-button>

              <p-button type="primary" size="sm" class="mx-1" @click.native.prevent="copyToClipboard" :disabled="!analysed">
                Copiar Resultado
              </p-button>

              <a href="/document/analysis/logs" class="btn btn-sm btn-primary mx-1">
                Logs
              </a>
            </div>
            <div class="clearfix"></div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Button as PButton} from '@/components'
import { OpenAIService } from '@/services/OpenAIService';

import "/node_modules/flag-icons/css/flag-icons.min.css";

export default {
  components: {
    PButton,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      formData: {
        title: "Análise de documentos",
        subTitle: "Informe os dados da análise",
        process: null,
        model: '',
        documentsModel: [], 
        documentsToCheck: [], 
      },
      infoData: {
        title: "Resultado",
        subTitle: "do documento analisado"
      },
      screenResultText: '<p>Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados</p>',
      screenResultPlainText: 'Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados',
      screenResultMarkdownText: '**Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados**',
      analysed: false, 
      responseLanguages: {
        portuguese: {
          plainText: 'Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados',
          html: '<p>Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados</p>',
          markdown: '**Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados**'
        }, 
        english: {
          plainText: null,
          html: null,
          markdown: null
        },
        spanish: {
          plainText: null,
          html: null,
          markdown: null
        }
      }
    };
  },
  mounted() {
    this.setHtmlResul()
  },
  methods: {
    async sendData() {
      
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        color: '#164464',
        loader: 'dots'
      });

      let processo = this.formData.process
      let modelos = this.formData.documentsModel
      let verificar = this.formData.documentsToCheck

      if (processo == null || processo == undefined) {
        loader.hide()

        this.$notify(
          {
            message: 'Informe o processo',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          }
        )

        return
      }
      
      if (modelos == null || modelos.length < 1) {
        loader.hide()

        this.$notify(
          {
            message: 'Informe um modelo de documento',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })

          return
      }
      
      if (verificar == null || verificar.length < 1) {
        loader.hide()

        this.$notify(
          {
            message: 'Informe os documentos a serem analisados',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })

          return
      }
      
      this.screenResultText = 'Analisando dados...'
      this.setHtmlResul()

      const openAIService = new OpenAIService();

      try {
        const formData = new FormData();
        formData.append("process", processo);

        modelos.forEach((modelFile) => {
          formData.append("modelFiles", modelFile);
        })
        
        verificar.forEach(((verifyFile) => {
          formData.append("filesToAnalysis", verifyFile);
        }))
        
        const response = await openAIService.postData(formData);
        
        this.responseLanguages = response.data
        console.log('this.responseLanguages: ', this.responseLanguages);

        this.screenResultText = this.responseLanguages.portuguese.html;
        this.screenResultPlainText = this.responseLanguages.portuguese.plainText;
        this.screenResultMarkdownText = this.responseLanguages.portuguese.markdown;
        
        this.setHtmlResul()
        this.analysed = true
      } catch (error) {
        this.$notify(
          {
            message: 'Erro ao consultar o chat gpt: '+ error,
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })

        this.screenResultText = '<div>' + error.message + '</div>'
        this.screenResultPlainText = error.message
        this.setHtmlResul()
        loader.hide()
        console.error('Erro ao consultar o chat gpt:', error);
      } finally {
        loader.hide()
      }
    }, 

    setHtmlResul() {
      let resultDiv = document.getElementById('result-card')
      let tempDiv = document.createElement('div')
      tempDiv.innerHTML = this.screenResultText
      resultDiv.innerHTML = ''

      while (tempDiv.firstChild) {
        resultDiv.appendChild(tempDiv.firstChild)
      }
    },

    newAnalysis() {
      this.formData.process = null
      this.formData.documentsModel = []
      this.formData.documentsToCheck = []
      this.screenResultText = 'Informe o processo, o arquivo de modelo e os documentos a serem analisados e clique em "Analisar" para obter resultados'
      this.analysed = false
    }, 

    setPortuguese() {
      this.screenResultPlainText = this.responseLanguages.portuguese.plainText;
      this.screenResultText = this.responseLanguages.portuguese.html;
      this.screenResultMarkdownText = this.responseLanguages.portuguese.markdown;
      this.setHtmlResul()
    }, 

    async setEnglish() {
      console.log('this.responseLanguages: ', this.responseLanguages);
      
      if (!this.responseLanguages.english.html)
        await this.getTranslate('english')

      this.screenResultPlainText = this.responseLanguages.english.plainText;
      this.screenResultText = this.responseLanguages.english.html;
      this.screenResultMarkdownText = this.responseLanguages.english.markdown;
      this.setHtmlResul()
    }, 

    async setSpanish() {
      if (!this.responseLanguages.spanish.html)
        await this.getTranslate('spanish')

      this.screenResultPlainText = this.responseLanguages.spanish.plainText;
      this.screenResultText = this.responseLanguages.spanish.html;
      this.screenResultMarkdownText = this.responseLanguages.spanish.markdown;
      this.setHtmlResul()
    },

    async getTranslate(language) {
      console.log('language: ', language);

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        color: '#164464',
        loader: 'dots'
      });

      let request = {
        text: this.screenResultMarkdownText, 
        language: language
      }

      const openAIService = new OpenAIService();

      try {
        const response = await openAIService.postTranslateRequest(request);
        
        let traslateResponse = response.data
        console.log('traslateResponse: ', traslateResponse);

        if (language == 'english') {
          this.responseLanguages.english = traslateResponse.english
          this.responseLanguages.englishHtml = traslateResponse.englishHtml
        }

        if (language == 'spanish') {
          this.responseLanguages.spanish = traslateResponse.spanish
          this.responseLanguages.spanishHtml = traslateResponse.spanishHtml
        }
        
        this.setHtmlResul()
      } catch (error) {
        this.$notify(
          {
            message: 'Erro ao traduzir: '+ error,
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger'
          })

        this.screenResultText = '<div>' + error.message + '</div>'
        this.screenResultPlainText = error.message
        this.setHtmlResul()
        loader.hide()
        console.error('Erro ao traduzir:', error);
      } finally {
        loader.hide()
      }
    },

    handleFileChange(type, event) {
      const files = Array.from(event.target.files);

      if (type === 'model') {
        files.forEach(file => {
          if (!this.fileAlreadyAdded(this.formData.documentsModel, file))
            this.formData.documentsModel.push(file)
        })
      } else if (type === 'verify') {
        files.forEach(file => {
          if (!this.fileAlreadyAdded(this.formData.documentsToCheck, file))
            this.formData.documentsToCheck.push(file)
        })
      }
    }, 

    fileAlreadyAdded(array, searchObj) {
      return array.some(obj => 
        obj.name === searchObj.name 
        && obj.size === searchObj.size 
        && obj.type === searchObj.type 
        && obj.lastModified === searchObj.lastModified
      );
    }, 

    removeItem(type, index) {
      if (type === 'model') {
        this.formData.documentsModel.splice(index, 1)
      } else if (type === 'verify') {
        this.formData.documentsToCheck.splice(index, 1)
      }
    }, 

    onCancel() {
      return
    },

    copyToClipboard() {
      if (!this.analysed)
        return
      
      // Cria um elemento de texto temporário para copiar o conteúdo
      const tempElement = document.createElement("textarea");
      tempElement.value = this.screenResultPlainText;
      
      document.body.appendChild(tempElement);
      tempElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempElement);

      this.$notify({
        message: 'Conteúdo copiado para a área de transferência!',
        icon: 'fa fa-gift',
        horizontalAlign: 'center',
        verticalAlign: 'bottom',
        type: 'success'
      })
    },
  },
};
</script>

<style scoped>
.horizontal-list {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.horizontal-list li {
  margin-right: 10px;
}

.horizontal-list a {
  margin-left: 5px;
  color: red;
  cursor: pointer;
}
.normal-cursor {
  cursor: default !important;
}

/* #result-card .h3 {
  font-size: 1.2em !important;
}

#result-card .h4 {
  font-size: 1.1em !important;
} */
</style>